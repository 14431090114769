/* Container to center the launcher on mid-right */
.launcher-container {
  display: flex;
  justify-content: center;  /* Center horizontally */
  align-items: center;      /* Center vertically */
  cursor: pointer;
  margin-left: auto;        /* Push to right while maintaining middle alignment */
  margin-right: 10%; 
  margin-top: 40px;
  float: right;       /* Adjust according to your needs */

}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    min-height: 72px;
    margin: 0;
}

.header-left {
    flex: 0 0 auto;
}

.header-center {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
}

.header-right {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.logo-link {
    display: flex;
    align-items: center;
    margin: 0px;
}

.Applogo {
    height: 36px;
    width: auto;
    transition: transform 0.2s ease;
}

.Applogo:hover {
    transform: scale(1.05);
}

.cost-savings-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    white-space: nowrap;
    position: relative;
}

.coins-icon {
    width: 28px;
    height: 28px;
}

.cost-savings-text {
    font-weight: bold;
    font-size: 1.1rem;
}

.error-text {
    color: #f44336;
    font-size: 0.8rem;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}

.welcome-text {
    white-space: nowrap;
    font-size: 1.1rem;
}

.launcher-icon {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2px;
    padding: 6px;
    width: 28px;
    height: 28px;
    cursor: pointer;
    background: transparent;
    border-radius: 6px;
    transition: all 0.2s ease;
}

.launcher-icon:hover {
    background-color: rgba(224, 224, 224, 0.87);
    transform: scale(1.05);
}

.dot {
    width: 5px;
    height: 5px;
    background-color: #333;
    border-radius: 50%;
}

.logout-button {
    padding: 0.7rem 1.4rem;
    background-color: #f95959;
    color: white;
    text-decoration: none;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1.1rem;
    white-space: nowrap;
    transition: all 0.2s ease;
}

.logout-button:hover {
    background-color: #f73737;
    transform: scale(1.05);
}

/* Responsive styles */
@media screen and (max-width: 1024px) {
    .header {
        padding: 0.75rem 1rem;
        min-height: 68px;
    }

    .cost-savings-text {
        font-size: 1rem;
    }

    .welcome-text {
        font-size: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .header {
        flex-wrap: wrap;
        gap: 0.5rem;
        padding: 0.5rem;
        min-height: 64px;
    }

    .header-left {
        flex: 0 0 auto;
    }

    .header-center {
        order: 3;
        flex: 1 0 100%;
        justify-content: flex-start;
        padding: 0.5rem 0;
    }

    .header-right {
        flex: 1;
        justify-content: flex-end;
    }

    .welcome-text {
        display: none;
    }

    .Applogo {
        height: 40px;
    }

    .coins-icon {
        width: 24px;
        height: 24px;
    }

    .error-text {
        font-size: 0.7rem;
        bottom: -16px;
    }

    .logout-button {
        padding: 0.6rem 1.2rem;
        font-size: 1rem;
    }
}

@media screen and (max-width: 480px) {
    .header {
        padding: 0.5rem;
        min-height: 60px;
    }

    .cost-savings-text {
        font-size: 0.9rem;
    }

    .logout-button {
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
    }

    .launcher-icon {
        width: 24px;
        height: 24px;
        padding: 4px;
    }

    .dot {
        width: 4px;
        height: 4px;
    }
}
