@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.tbody{
  font-size: small;
}

.home {
  margin-top: 86px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: white rgba(0, 0, 0, 0.6);
}


.App-header {
  margin: 20px;
  color: #333; /* Darker text color for better readability */
}

.table-container {
  width: 100%;
  margin: 20px auto;
  padding: 10px;
  box-sizing: border-box;
  overflow-x: auto; /* Allow horizontal scrolling */
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the title */
  margin-bottom: 10px;
  position: relative; /* Position search bar absolutely within this container */
  
}

.header-title {
  font-size: 10px; /* Font size for the title */
  font-weight: bold;
  margin-right: 120px; /* Space for the search bar */
  text-align: center; /* Center align header text */
}

.search-container {
  position: absolute;
  right: 20px; /* Distance from the right edge of the container */
  display: flex;
  align-items: center;
  gap: 10px; /* Gap between the search icon and input */
  width: calc(100% - 120px); /* Adjust width to accommodate the search bar */
  max-width: 100%; /* Ensure it doesn't exceed container width */
}

.search-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  transition: width 0.3s ease-in-out;
  width: 0; /* Start with zero width for animation effect */
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 9px; /* Slightly larger font size for better readability */
  border-radius: 10px;
  width: 100%;
  border: 2px solid #000000;
  overflow: hidden;

}

.data-table th, .data-table td {
  border: 1px solid #ffffff; /* Lighter gray border for better contrast */
  padding: 10px; /* Increased padding for better spacing */
  text-align: justify;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data-table th {
  background-color: #0AE289; /* Updated color */
  color: white; /* White text color for headers */
  text-align: center; /* Center align header text */
}

.data-table tr:nth-child(even) {
  background-color: #fcfcfc; /* Light gray background for even rows */
}

.data-table tr:nth-child(odd) {
  background-color: #ffffff; /* White background for odd rows */
}

.data-table tr:hover {
  background-color: #ffffff; /* Light gray background on hover */
}


.error-message {
  color: #E68369; /* Error message color */
  font-weight: bold;
}

/* Pagination styling */
.pagination {
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-top: 10px;
}

.pagination a {
  margin: 0 5px;
  padding: 8px 16px;
  text-decoration: none;
  color: #0AE289;
  border: 1px solid #ffffff;
  border-radius: 4px;
  cursor: pointer;
}

.pagination .active a {
  background-color: #0AE289;
  color: white;
}

.release-button{
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-weight: 100;
  border-radius: 10px;
  padding: 12px 24px;
  border: 0;
  color: #000021;
  background: #0AE289;
  line-height: 1.15;
  font-size: 12px;
  :hover {
      transition: all .1s ease;
      box-shadow: 0 0 0 0 #fff, 0 0 0 3px #0AE289;
  }

  }

.create-ami-button{
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-weight: 100;
  border-radius: 10px;
  padding: 12px 24px;
  border: 0;
  color: #000021;
  background: #0AE289;
  line-height: 1.15;
  font-size: 12px;
  :hover {
      transition: all .1s ease;
      box-shadow: 0 0 0 0 #fff, 0 0 0 3px #0AE289;
  }
}



.backup-button{
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-weight: 100;
  border-radius: 10px;
  padding: 12px 12px;
  border: 0;
  color: #000021;
  background: #0AE289;
  line-height: 1.15;
  font-size: 12px;
  :hover {
      transition: all .1s ease;
      box-shadow: 0 0 0 0 #fff, 0 0 0 3px #0AE289;
  }
}



.delete-button{
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-weight: 90;
  border-radius: 10px;
  padding: 12px 12px;
  border: 0;
  color: #ffffff;
  background: #f44336;
  line-height: 1.15;
  font-size: 11px;
  position: relative;
  transition: all 0.1s ease;
  z-index: 1;
}

.migrate-button{
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-weight: 100;
  border-radius: 10px;
  padding: 12px 24px;
  border: 0;
  color: #000021;
  background: #0AE289;
  line-height: 1.15;
  font-size: 12px;
  :hover {
      transition: all .1s ease;
      box-shadow: 0 0 0 0 #fff, 0 0 0 3px #0AE289;
  }
}



.home-container {
  transition: filter 0.3s ease;
}

.home-container.blurred {
  filter: blur(8px);  /* Apply the blur effect */
}
