.custom-legend {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-size: 14px;
}

.legend-color {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 5px;
}

.my-tooltip-body-class {
  z-index: 2; /* Set z-index to 2 */
}
