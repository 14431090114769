.App {
  background-image: url('/public/Background.png');
  /* Correct path for public folder */
  background-size: cover;
  /* Ensures the entire image fits within the container */
  background-repeat: no-repeat;
  /* Prevents the image from repeating */
  background-position: center;
  /* Centers the image */
  width: 100vw;
  height: 100vh;
  position: fixed;
  box-sizing: border-box;
  font-family: 'Inter', Arial, sans-serif;
  font-weight: 400;
  padding: 0 10px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.main-container {
  margin-top: 86px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: white rgba(0, 0, 0, 0.6);
  padding: 40px;
  zoom: 0.8;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.effect-style-glassmorph-blur {
  backdrop-filter: blur(20px);
}

.glassmorph-container {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-glassmorph-stroke);
}

.glassmorph-container-body {
  width: 100%;
  height: fit-content;
  display: flex;
  padding: 40px;
  gap: 20px;
  flex-direction: column;
  border: 1px solid var(--color-glassmorph-stroke);
  border-radius: 8px;
}

.opaque-glass{
  background-color: #ecf4ec;
}

.glassmorph {
  background: rgba(255, 255, 255, 0.45);
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
}


.glassmorph-lv2 {
  background: rgba(255, 255, 255, 0.20);
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
}

.glassmorph-lv1 {
  background: rgba(255, 255, 255, 0.30);
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
}

.glassmorph-lv3 {
  background: rgba(255, 255, 255, 0.10);
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
}

.glassmorph-stroke {
  border: 1px solid rgba(255, 255, 255, 0.61);
}

.header {
  display: flex;
  /* Flexbox for proper alignment */
  align-items: center;
  /* Vertically center all items */
  justify-content: space-between;
  /* Space between logo and launcher */
  width: 100%;
  background-color: #ffffff;
  color: #0c0c0c;
  padding: 1%;
  position: fixed;
  /* Make the header fixed */
  top: 0;
  /* Align it to the top of the page */
  left: 0;
  /* Align it to the left */
  right: 0;
  /* Align it to the right */
  z-index: 1000;
  /* Ensure it stays above other content */
}

body.with-header {
  padding-top: 70px;
  /* Padding for pages with the fixed header */
}


.logo {
  width: 200px;
  height: 100px;
  margin-right: 15px;
}

.Applogo {
  width: 183px;
  height: 60px;
  margin-right: 15px;
}

.App-link {
  color: #61dafb;
}

.errmsg {
  color: red;
}

a {
  text-decoration: none !important;
  color: white !important;
  padding: 2px;
}

app-check {
  height: 20px;
  width: 20px;
  margin: 1%;
}

a {
  text-decoration: none !important;
  color: rgb(0, 0, 0) !important;
  padding: 2px;
  margin-top: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.imaged {
  height: 70px;
  width: auto;
}

.page-content {
  padding-top: 80px;
  /* Adjust this value based on your AppHeader height */
  min-height: calc(100vh - 80px);
}


.font-size-8 {
  font-size: 8px;
  margin: 0px;
  line-height: 10px;
  letter-spacing: normal;
}

.font-size-10 {
  font-size: 10px;
  margin: 0px;
  line-height: 12px;
  letter-spacing: normal;
}

.font-size-12 {
  font-size: 12px;
  margin: 0px;
  line-height: 15px;
  letter-spacing: normal;
}

.font-size-14 {
  font-size: 14px;
  margin: 0px;
  line-height: 17px;
  letter-spacing: normal;
}

.font-size-16 {
  font-size: 16px;
  margin: 0px;
  line-height: 19px;
  letter-spacing: normal;
}

.font-size-18 {
  font-size: 18px;
  margin: 0px;
  line-height: 22px;
  letter-spacing: normal;
}

.font-size-20 {
  font-size: 20px;
  margin: 0px;
  line-height: 24px;
  letter-spacing: normal;
}

.font-size-22 {
  font-size: 22px;
  margin: 0px;
  line-height: 27px;
  letter-spacing: normal;
}

.font-size-24 {
  font-size: 24px;
  margin: 0px;
  line-height: 29px;
  letter-spacing: normal;
}


/* Width */

.width-fillavailable {

  width: 100%;

}

.width-fillfullscreen {

  width: 100vw;
}

.width-hugcontent {

  width: fit-content;

}

/* Horizontal and Vertical Padding */

/* Vertical Padding */
.verticalPadding4and8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.verticalPadding8and12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.verticalPadding12and16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.verticalPadding16and20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.verticalPadding20and24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.verticalPadding24and28 {
  padding-top: 28px;
  padding-bottom: 28px;
}

.verticalPadding28and32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.verticalPadding32and36 {
  padding-top: 36px;
  padding-bottom: 36px;
}

.verticalPadding36and40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.verticalPadding40and44 {
  padding-top: 44px;
  padding-bottom: 44px;
}

/* Horizontal Padding */
.horizontalPadding4and8 {
  padding-left: 8px;
  padding-right: 8px;
}

.horizontalPadding8and12 {
  padding-left: 12px;
  padding-right: 12px;
}

.horizontalPadding12and16 {
  padding-left: 16px;
  padding-right: 16px;
}

.horizontalPadding16and20 {
  padding-left: 20px;
  padding-right: 20px;
}

.horizontalPadding20and24 {
  padding-left: 24px;
  padding-right: 24px;
}

.horizontalPadding24and28 {
  padding-left: 28px;
  padding-right: 28px;
}

.horizontalPadding28and32 {
  padding-left: 32px;
  padding-right: 32px;
}

.horizontalPadding32and36 {
  padding-left: 36px;
  padding-right: 36px;
}

.horizontalPadding36and40 {
  padding-left: 40px;
  padding-right: 40px;
}

.horizontalPadding40and44 {
  padding-left: 44px;
  padding-right: 44px;
}

@media (max-width: 500px) {

  .verticalPadding4and8 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .verticalPadding8and12 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .verticalPadding12and16 {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .verticalPadding16and20 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .verticalPadding20and24 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .verticalPadding24and28 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .verticalPadding28and32 {
    padding-top: 28px;
    padding-bottom: 28px;
  }

  .verticalPadding32and36 {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .verticalPadding36and40 {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  .verticalPadding40and44 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .horizontalPadding4and8 {
    padding-left: 4px;
    padding-right: 4px;
  }

  .horizontalPadding8and12 {
    padding-left: 8px;
    padding-right: 8px;
  }

  .horizontalPadding12and16 {
    padding-left: 12px;
    padding-right: 12px;
  }

  .horizontalPadding16and20 {
    padding-left: 16px;
    padding-right: 16px;
  }

  .horizontalPadding20and24 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .horizontalPadding24and28 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .horizontalPadding28and32 {
    padding-left: 28px;
    padding-right: 28px;
  }

  .horizontalPadding32and36 {
    padding-left: 32px;
    padding-right: 32px;
  }

  .horizontalPadding36and40 {
    padding-left: 36px;
    padding-right: 36px;
  }

  .horizontalPadding40and44 {
    padding-left: 40px;
    padding-right: 40px;
  }
}



/* Padding */

.padding4and8 {
  padding: 8px;
}

.padding8and12 {
  padding: 12px;
}

.padding12and16 {
  padding: 16px;
}

.padding16and20 {
  padding: 20px;
}

.padding20and24 {
  padding: 24px;
}

.padding24and28 {
  padding: 28px;
}

.padding28and32 {
  padding: 32px;
}

.padding32and36 {
  padding: 36px;
}

.padding36and40 {
  padding: 40px;
}

.padding40and44 {
  padding: 44px;
}

@media (max-width: 500px) {

  .padding4and8 {
    padding: 4px;
  }

  .padding8and12 {
    padding: 8px;
  }

  .padding12and16 {
    padding: 12px;
  }

  .padding16and20 {
    padding: 16px;
  }

  .padding20and24 {
    padding: 20px;
  }

  .padding24and28 {
    padding: 24px;
  }

  .padding28and32 {
    padding: 28px;
  }

  .padding32and36 {
    padding: 32px;
  }

  .padding36and40 {
    padding: 36px;
  }

  .padding40and44 {
    padding: 40px;
  }
}

/*Row*/

.flexrow {

  flex-direction: row;

}

.flexcolumn {

  flex-direction: column;

}

/*Gap*/

.gap4and8 {
  gap: 8px;
  display: flex;
}

.gap8and12 {
  gap: 12px;
  display: flex;
}

.gap12and16 {
  gap: 16px;
  display: flex;
}

.gap16and20 {
  gap: 20px;
  display: flex;
}

.gap20and24 {
  gap: 24px;
  display: flex;
}

.gap24and28 {
  gap: 28px;
  display: flex;
}

.gap28and32 {
  gap: 32px;
  display: flex;
}

.gap32and36 {
  gap: 36px;
  display: flex;
}

.gap36and40 {
  gap: 40px;
  display: flex;
}

.gap40and44 {
  gap: 44px;
  display: flex;
}


@media (max-width: 500px) {

  .gap4and8 {
    gap: 4px;
  }

  .gap8and12 {
    gap: 8px;
  }

  .gap12and16 {
    gap: 12px;
  }

  .gap16and20 {
    gap: 16px;
  }

  .gap20and24 {
    gap: 20px;
  }

  .gap24and28 {
    gap: 24px;
  }

  .gap28and32 {
    gap: 28px;
  }

  .gap32and36 {
    gap: 32px;
  }

  .gap36and40 {
    gap: 36px;
  }

  .gap40and44 {
    gap: 40px;
  }
}

/* Colors */

/* Hover */

.primary-violet-background:hover {
  background-color: #6C0BA9;
}

.primary-violet {
  color: #6C0BA9;
}

/* Primary Blue */
.primary-text {
  color: #1c1b1f;
}

.primary-violet-background {
  background-color: #A020F0;
}

.fill-primary-violet-background {
  background-color: #EED1FF;
}

/* Border Stroke */
.border-stroke {
  color: rgba(2, 63, 94, 0.2);
}

.border-stroke-background {
  background-color: rgba(2, 63, 94, 0.2);
}

/* Unavailable Blue */
.unavailable-blue {
  color: #A9B8C6;
}

.unavailable-blue-background {
  background-color: #A9B8C6;
}

/* Inconsequential Grey */
.inconsequential-grey {
  color: #66666A;
}

.inconsequential-grey-background {
  background-color: #66666A;
}

/* Highlight Blue */
.highlight-blue {
  color: #007EB4;
}

.highlight-blue-background {
  background-color: #007EB4;
}

/* Warning Red */
.warning-red {
  color: #DA5E58;
}

.warning-red-background {
  background-color: #DA5E58;
}

/* Positive Green */
.positive-green {
  color: #4FBA5A;
}

.positive-green-background {
  background-color: #4FBA5A;
}

/* Fill Highlight Blue */
.fill-highlight-blue {
  color: #F3F5F7;
}

.fill-highlight-blue-background {
  background-color: #F3F5F7;
}

/* Inconsequential Yellow */
.inconsequential-yellow {
  color: #FFC700;
}

.inconsequential-yellow-background {
  background-color: #FFC700;
}

/* Fill Highlight Red */
.fill-highlight-red {
  color: #F5D6D6;
}

.fill-highlight-red-background {
  background-color: #F5D6D6;
}

/* Fill Highlight Yellow */
.fill-highlight-yellow {
  color: #F5F3D6;
}

.fill-highlight-yellow-background {
  background-color: #F5F3D6;
}

/* Fill Highlight Green */
.fill-highlight-green {
  color: #D6F5D9;
}

.fill-highlight-green-background {
  background-color: #D6F5D9;
}

/* Background White */
.background-white {
  background: #FFFFFF;
}

.background-white-color {
  color: #FFFFFF;

}

.background-white-fill {
  fill: #FFFFFF;

}

/* Modal Background */
.modal-background {
  background: rgba(0, 0, 0, 0.6);
}

/* Primary Blue */
.primary-blue-fill {
  fill: #023F5E;
  color: #023F5E;
}

/* Unavailable Blue */
.unavailable-blue-fill {
  fill: #A9B8C6;
  color: #A9B8C6;
}

/* Highlight Blue */
.highlight-blue-fill {
  fill: #007EB4;
  color: #007EB4;
}

/* Warning Red */
.warning-red-fill {
  fill: #DA5E58;
  color: #DA5E58;
}

/* Positive Green */
.positive-green-fill {
  fill: #4FBA5A;
  color: #4FBA5A;
}

/* Inconsequential Yellow */
.inconsequential-yellow-fill {
  fill: #FFC700;
  color: #FFC700;
}

/* Inconsequential Grey */
.inconsequential-grey-fill {
  fill: #66666A;
  color: #66666A;
}



/* Text */

/* Font Sizes */
@media (max-width: 500px) {
  .font-size-8and12 {
    font-size: 8px;
    margin: 0px;
    line-height: 10px;
    letter-spacing: normal;
  }

  .font-size-10and14 {
    font-size: 10px;
    margin: 0px;
    line-height: 12px;
    letter-spacing: normal;
  }

  .font-size-12and16 {
    font-size: 12px;
    margin: 0px;
    line-height: 15px;
    letter-spacing: normal;
  }

  .font-size-14and18 {
    font-size: 14px;
    margin: 0px;
    line-height: 17px;
    letter-spacing: normal;
  }

  .font-size-16and20 {
    font-size: 16px;
    margin: 0px;
    line-height: 19px;
    letter-spacing: normal;
  }

  .font-size-18and22 {
    font-size: 18px;
    margin: 0px;
    line-height: 22px;
    letter-spacing: normal;
  }

  .font-size-20and24 {
    font-size: 20px;
    margin: 0px;
    line-height: 24px;
    letter-spacing: normal;
  }

  .font-size-22and26 {
    font-size: 22px;
    margin: 0px;
    line-height: 27px;
    letter-spacing: normal;
  }

  .font-size-24and28 {
    font-size: 24px;
    margin: 0px;
    line-height: 29px;
    letter-spacing: normal;
  }
}

@media (min-width: 500px) {
  .font-size-8and12 {
    font-size: 12px;
    margin: 0px;
    line-height: 15px;
    letter-spacing: normal;
  }

  .font-size-10and14 {
    font-size: 14px;
    margin: 0px;
    line-height: 17px;
    letter-spacing: normal;
  }

  .font-size-12and16 {
    font-size: 16px;
    margin: 0px;
    line-height: 19px;
    letter-spacing: normal;
  }

  .font-size-14and18 {
    font-size: 18px;
    margin: 0px;
    line-height: 22px;
    letter-spacing: normal;
  }

  .font-size-16and20 {
    font-size: 20px;
    margin: 0px;
    line-height: 24px;
    letter-spacing: normal;
  }

  .font-size-18and22 {
    font-size: 22px;
    margin: 0px;
    line-height: 27px;
    letter-spacing: normal;
  }

  .font-size-20and24 {
    font-size: 24px;
    margin: 0px;
    line-height: 29px;
    letter-spacing: normal;
  }

  .font-size-22and26 {
    font-size: 26px;
    margin: 0px;
    line-height: 31px;
    letter-spacing: normal;
  }

  .font-size-24and28 {
    font-size: 28px;
    margin: 0px;
    line-height: 34px;
    letter-spacing: normal;
  }
}

/* Font Weights */
.font-weight-normal {
  font-weight: 400;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-semibold {
  font-weight: 600;
}

.font-weight-bold {
  font-weight: 700;
}

.font-weight-extrabold {
  font-weight: 800;
}

.font-weight-black {
  font-weight: 900;
}

/* Icons */

@media (max-width: 500px) {
  .icon-12and16 {
    width: 12px;
    height: 12px;
  }

  .icon-16and20 {
    width: 16px;
    height: 16px;
  }

  .icon-20and24 {
    width: 20px;
    height: 20px;
  }

  .icon-24and28 {
    width: 24px;
    height: 24px;
  }

  .icon-28and32 {
    width: 28px;
    height: 28px;
  }

  .icon-32and36 {
    width: 32px;
    height: 32px;
  }

  .icon-36and40 {
    width: 36px;
    height: 36px;
  }

  .icon-40and44 {
    width: 40px;
    height: 40px;
  }

  .icon-44and48 {
    width: 44px;
    height: 44px;
  }
}

@media (min-width: 500px) {
  .icon-12and16 {
    width: 16px;
    height: 16px;
  }

  .icon-16and20 {
    width: 20px;
    height: 20px;
  }

  .icon-20and24 {
    width: 24px;
    height: 24px;
  }

  .icon-24and28 {
    width: 28px;
    height: 28px;
  }

  .icon-28and32 {
    width: 32px;
    height: 32px;
  }

  .icon-32and36 {
    width: 36px;
    height: 36px;
  }

  .icon-36and40 {
    width: 40px;
    height: 40px;
  }

  .icon-40and44 {
    width: 44px;
    height: 44px;
  }

  .icon-44and48 {
    width: 48px;
    height: 48px;
  }
}

/* Page Structure.css */

.PageStructure {
  padding: 72px 40px 20px 280px;
  /* top, right, bottom, left */
  gap: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  margin: 0px;
  overflow-y: auto;
  flex-direction: column;

}

@media (max-width: 1024px) and (min-width: 500px) {
  .PageStructure {
    padding: 72px 20px 83px;
    /* top, right/left, bottom */
    gap: 24px;
  }
}

@media (max-width: 500px) {
  .PageStructure {
    padding: 60px 20px 74px;
    /* top, right/left, bottom */
    gap: 20px;
  }
}

/* Modals */

/* Modal Position */
@media (max-width: 500px) {
  .modal-position {
    width: 100%;
    bottom: 0;
    z-index: 100;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 20px 16px 20px;
    box-sizing: border-box;
    gap: 20px;
  }
}

@media (min-width: 500px) {
  .modal-position {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px 24px 20px 24px;
    box-sizing: border-box;
    gap: 24px;
  }
}

/* Modal Background */
@media (max-width: 800px) {
  .modal-background {
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: fixed;
    z-index: 100;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    box-sizing: border-box;
  }
}

@media (min-width: 800px) {
  .modal-background {
    width: 100%;
    height: 100%;
    padding-left: 120px;
    padding-right: 120px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    z-index: 100;
    box-sizing: border-box;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
}

/* Stroke */

/* Normal Stroke */

.normal-stroke {
  box-shadow: inset 0 0 0 1px;
}

/* Normal Top Stroke */
.normal-top-stroke {
  box-shadow: inset 0 1px 0 0;
}

/* Normal Bottom Stroke */
.normal-bottom-stroke {
  box-shadow: inset 0 -1px 0 0;
}

@media (max-width: 500px) {
  .normal-stroke {
    box-shadow: inset 0 0 0 0.5px;
  }

  .normal-top-stroke {
    box-shadow: inset 0 0.5px 0 0;
  }

  .normal-bottom-stroke {
    box-shadow: inset 0 -0.5px 0 0;
  }
}

/* Special Stroke */

.special-stroke {
  box-shadow: inset 0 0 0 2px;
}

/* Special Top Stroke */
.special-top-stroke {
  box-shadow: inset 0 2px 0 0;
}

/* Special Bottom Stroke */
.special-bottom-stroke {
  box-shadow: inset 0 -2px 0 0;
}

@media (max-width: 500px) {
  .special-stroke {
    box-shadow: inset 0 0 0 1px;
  }

  .special-top-stroke {
    box-shadow: inset 0 1px 0 0;
  }

  .special-bottom-stroke {
    box-shadow: inset 0 -1px 0 0;
  }
}

/* Pointer */

.pointer-default {
  cursor: default;
}

.pointer-pointer {
  cursor: pointer;
}

.pointer-not-allowed {
  cursor: not-allowed;
}

.pointer-crosshair {
  cursor: crosshair;
}

.pointer-help {
  cursor: help;
}

.pointer-move {
  cursor: move;
}

.pointer-text {
  cursor: text;
}

.pointer-wait {
  cursor: wait;
}

/* Border Radius */

.border-radius-4 {
  border-radius: 4px;
}

.border-radius-8 {
  border-radius: 8px;
}

.border-radius-12 {
  border-radius: 12px;
}

.border-radius-16 {
  border-radius: 16px;
}

.border-radius-20 {
  border-radius: 20px;
}

.border-radius-24 {
  border-radius: 24px;
}

.border-radius-28 {
  border-radius: 28px;
}

.border-radius-32 {
  border-radius: 32px;
}

.border-radius-36 {
  border-radius: 36px;
}

.border-radius-40 {
  border-radius: 40px;
}


/* For screens under 500px */
@media (max-width: 500px) {
  .allborders4and8 {
    border-radius: 4px;
  }

  .topborders4and8 {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .bottomborders4and8 {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .leftborders4and8 {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .rightborders4and8 {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .allborders8and12 {
    border-radius: 8px;
  }

  .topborders8and12 {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .bottomborders8and12 {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .leftborders8and12 {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .rightborders8and12 {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .allborders12and16 {
    border-radius: 12px;
  }

  .topborders12and16 {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .bottomborders12and16 {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .leftborders12and16 {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .rightborders12and16 {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .allborders16and20 {
    border-radius: 16px;
  }

  .topborders16and20 {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .bottomborders16and20 {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .leftborders16and20 {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .rightborders16and20 {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}

/* For screens over 500px */
@media (min-width: 500px) {
  .allborders4and8 {
    border-radius: 8px;
  }

  .topborders4and8 {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .bottomborders4and8 {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .leftborders4and8 {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .rightborders4and8 {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .allborders8and12 {
    border-radius: 12px;
  }

  .topborders8and12 {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .bottomborders8and12 {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .leftborders8and12 {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .rightborders8and12 {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .allborders12and16 {
    border-radius: 16px;
  }

  .topborders12and16 {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .bottomborders12and16 {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .leftborders12and16 {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }

  .rightborders12and16 {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .allborders16and20 {
    border-radius: 20px;
  }

  .topborders16and20 {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .bottomborders16and20 {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .leftborders16and20 {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .rightborders16and20 {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}