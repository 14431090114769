.tab {
    position: relative;
    background: rgba(255, 255, 255, 0.30);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  
    &[data-active] {
      z-index: 1;
      background-color: #A020F0;
      color: var(--mantine-color-white);
    }
  }