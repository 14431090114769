.launcher-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.launcher-menu-overlay.active {
  opacity: 1;
  visibility: visible;
}

.launcher-menu {
  position: absolute;
  top: 60px;
  right: 10px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  width: 300px;
  padding: 10px;
  z-index: 10000;
  transform: scale(0.9);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.launcher-menu-overlay.active .launcher-menu {
  transform: scale(1);
  opacity: 1;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}

.service-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.service-item:hover {
  background-color: #f5f5f5;
}

.service-item img {
  width: 32px;
  height: 32px;
  object-fit: contain;
  margin-bottom: 4px;
}

.service-item span {
  font-size: 10px;
  color: #5f6368;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}