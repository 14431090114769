.dashboard-container {
  padding: 2rem;
  background: #27296d;
  min-height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
}

.table-container {
  margin-top: 20px;
  padding: 1rem;
  background: #27296d;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
}

.table-wrapper {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1rem;
  border-radius: 8px;
}

.data-table {
  width: 100%;
  min-width: 1200px;
  border-collapse: collapse;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  font-size: 12px;
  table-layout: fixed;
  position: relative;
  z-index: 1;
}

.data-table th, 
.data-table td {
  padding: 0.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.data-table th {
  background-color: #ADD8E6;
  color: #000;  
  padding: 8px;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  font-size: 12px;
  position: sticky;
  top: 0;
  z-index: 2;
}

.data-table td {
  padding: 6px 8px;
  border: 1px solid #e0e0e0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.data-table th:nth-child(1),
.data-table td:nth-child(1) { width: 11%; }  /* Instance Id */

.data-table th:nth-child(2),
.data-table td:nth-child(2) { width: 11%; }  /* Instance Name */

.data-table th:nth-child(3),
.data-table td:nth-child(3) { width: 11%; }  /* Region */

.data-table th:nth-child(4),
.data-table td:nth-child(4) { width: 9%; }   /* Current Instance Type */

.data-table th:nth-child(5),
.data-table td:nth-child(5) { width: 9%; }   /* CurrentHourlyOnDemandRate */

.data-table th:nth-child(6),
.data-table td:nth-child(6) { width: 9%; }   /* Recommended Instance Type */

.data-table th:nth-child(7),
.data-table td:nth-child(7) { width: 9%; }   /* EstimatedHourlyOnDemandRate */

.data-table th:nth-child(8),
.data-table td:nth-child(8) { width: 11%; }  /* Recommendation Type */

.data-table th:nth-child(9),
.data-table td:nth-child(9) { width: 9%; }   /* Hourly Savings */

.data-table th:nth-child(10),
.data-table td:nth-child(10) { width: 11%; } /* Action */

.data-table tr:nth-child(even) {
  background-color: #f5f5f5;
}

.data-table tr:hover {
  background-color: #f0f7ff;
}

.data-table td:hover {
  position: relative;
}

.data-table td:hover::after {
  content: attr(title);
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 11px;
  white-space: nowrap;
  z-index: 1000;
}

.action-button {
  padding: 6px 12px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  margin: 0 4px;
}

.action-button:hover {
  background-color: #1976d2;
}

.migrate-button {
  background-color: #27296d;
  color: white;
  border: none;
  padding: 4px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
  font-size: 11px;
  min-width: 70px;
}

.migrate-button:hover {
  background-color: #1a1b45;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.migrate-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.backup-button {
  background: #2ecc71;
  color: white;
}

.backup-button:hover {
  background: #27ae60;
}

.delete-button {
  background: #e74c3c;
  color: white;
}

.delete-button:hover {
  background: #c0392b;
}

.create-ami-button {
  background: #9b59b6;
  color: white;
}

.create-ami-button:hover {
  background: #8e44ad;
}

.release-button {
  background: #f1c40f;
  color: #2c3e50;
}

.release-button:hover {
  background: #f39c12;
}

.search-container {
  margin-bottom: 1.5rem;
}

.search-input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 2px solid #e1e8ed;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s;
}

.search-input:focus {
  border-color: #3498db;
  outline: none;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.1);
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 20px 0;
}

.pagination-button {
  padding: 8px 16px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  min-width: 40px;
  text-align: center;
}

.pagination-button:hover {
  background-color: #1976d2;
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-button.active {
  background-color: #1976d2;
  font-weight: bold;
}

.pagination-number {
  padding: 8px 16px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  min-width: 40px;
  text-align: center;
}

.pagination-number:hover {
  background-color: #1976d2;
}

.pagination-number.active {
  background-color: #1976d2;
  font-weight: bold;
}

.status-badge {
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
  font-size: 0.875rem;
  font-weight: 500;
}

.status-badge.latest {
  background: #e3f2fd;
  color: #1976d2;
}

.status-badge.outdated {
  background: #ffebee;
  color: #c62828;
}

.loading-spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem;
  background: #34495e;
  color: white;
  border-radius: 4px;
  font-size: 0.875rem;
  white-space: nowrap;
  z-index: 1000;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-brand {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.navbar-logo {
  width: 180px;
  height: auto;
  margin-right: 1rem;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.navbar-logo:hover {
  transform: scale(1.05);
}

.table-title {
  color: white;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  position: relative;
  z-index: 2;
  background: #27296d;
  padding: 0.5rem 0;
}

/* Snapshot table specific styles */
.snapshot-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  font-size: 11px;
  table-layout: fixed;
}

.snapshot-table th {
  background-color: #ADD8E6;
  color: #000;
  padding: 6px;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  font-size: 11px;
}

.snapshot-table td {
  width: 100%;
  min-width: 1600px; /* Adjust this based on your table's column widths */
  border-collapse: collapse;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  font-size: 12px;
  table-layout: fixed;
}

/* Column widths for snapshot table */
.snapshot-table th:nth-child(1),
.snapshot-table td:nth-child(1) { width: 2%; }  /* LockValue */

.snapshot-table th:nth-child(2),
.snapshot-table td:nth-child(2) { width: 2%; }  /* VolumeId */

.snapshot-table th:nth-child(3),
.snapshot-table td:nth-child(3) { width: 15%; }  /* Volume Name */

.snapshot-table th:nth-child(4),
.snapshot-table td:nth-child(4) { width: 57%; }  /* SnapshotId: SnapshotTime */

.snapshot-table tr:nth-child(even) {
  background-color: #f5f5f5;
}

.snapshot-table tr:hover {
  background-color: #f0f7ff;
}

/* Add tooltip for truncated content in snapshot table */
.snapshot-table td:hover {
  position: relative;
}

.snapshot-table td:hover::after {
  content: attr(title);
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 10px;
  white-space: nowrap;
  z-index: 1000;
}

.top-3-table {
  margin-top: 2rem;
  position: relative;
  z-index: 1;
}

.top-3-table .table-title {
  margin-bottom: 1rem;
  background: #27296d;
  padding: 0.5rem 0;
}
