.lambda-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.lambda-table-container {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  backdrop-filter: blur(6px);
  width: 92%;
  margin-bottom: 20px;
}

.lambda-table-header {
  background-color: #ADD8E6; /* Light blue header */
  color: white;
  font-weight: bold;
  text-align: center;
}

.lambda-table-row {
  &:nth-of-type(odd) {
      background-color: #f5f5f5; /* Light gray for odd rows */
  }
}

.image-preview {
  width: 60px;
  height: auto;
  object-fit: contain;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

/* Button container styling */
.lambda-button-container {
  width: 88%;
  display: flex;
  justify-content: flex-end;
  padding-right: 100px;
  margin-top: 10px;
}

/* Pagination styling */
.lambda-pagination-container {
  width: 88%;
  display: flex;
  justify-content: flex-end;
  padding-right: 100px;
  margin-top: 10px;
}

.page-content {
  padding-top: 80px;
  min-height: calc(100vh - 80px);
}

.lambda-title {
  margin-bottom: 30px;
  color: #333;
  font-size: 2rem;
}

.lambda-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.lambda-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.lambda-card:hover {
  transform: translateY(-5px);
}

.lambda-card h3 {
  margin-bottom: 15px;
  color: #2c3e50;
}

.lambda-details p {
  margin: 10px 0;
  color: #34495e;
}
