/* Wrapper for side-by-side charts */
.chart-wrapper {
    display: flex; /* Align charts side-by-side */
    justify-content: space-evenly; /* Add even spacing */
    align-items: flex-start; /* Align items to the top */
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
    gap: 20px; /* Add spacing between the charts */
    padding: 20px;
  }
  
  /* Fixed dimensions for all chart containers */
  .chart-container {
    width: 450px; /* Set a fixed width for the charts */
    height: 420px; /* Set a fixed height for the charts */
    background-color: #ffffff; /* Light background */
    border: 1px solid #ddd; /* Border for visual separation */
    border-radius: 8px; /* Rounded corners for aesthetics */
    display: flex;
    flex-direction: column; /* Stack content inside */
    justify-content: center; /* Center align content vertically */
    align-items: center; /* Center align content horizontally */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
    padding: 10px;
  }
  
  /* Consistent title styling for all charts */
  .chart-container h2 {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
  }
  